<template>
  <div id="search">
    <div :class="brand === 14 ? 'mt-8' : 'mt-16'">
      <v-layout wrap>
        <v-flex
          class="xs6 sm4 md3 py-5"
          v-for="(item, index) in shop"
          :key="index"
        >
          <v-layout wrap @click="toProduct(item)">
            <v-flex xs12 class="shop-item px-2">
              <img
                v-bind:src="item.url_image"
                :class="isMobile ? 'shop-img-m' : 'shop-img'"
                :alt="item.url_image"
                @error="handleImageError"
              />
            </v-flex>
            <v-flex xs12 class="px-2 text-center">
              <p class="text">{{ item.product_name }}</p>
              <p
                v-if="item.harga_coret && item.harga_coret !== '0'"
                class="text-through"
              >
                {{ addComma(item.harga_coret) }} IDR
              </p>
              <p class="text">
                {{
                  item.selling_price === 0
                    ? "???"
                    : addComma(item.selling_price) + " IDR"
                }}
              </p>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <div v-if="shop && shop.length === 0">
        <div class="empty-product">
          <h1 class="ep-label">Product Not Available</h1>
        </div>
      </div>
      <div class="pb-8 text-center">
        <v-pagination
          v-model="page"
          v-if="totalPages > 1"
          color="black"
          :length="totalPages"
          :total-visible="5"
          @input="updatePage($event)"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "@/axios";

export default {
  name: "Search",
  computed: {
    ...mapState(["isMobile"]),
  },
  data() {
    return {
      search: "",
      shop: [],
      page: 1,
      limit: 8,
      totalPages: 0,
      brand: process.env.VUE_APP_BRAND,
    };
  },
  methods: {
    handleImageError(event) {
      event.target.src = "https://spaces.ilfen.co/assets/image/default.png";
    },
    updatePage($event) {
      this.page = $event;
      this.searchData();
    },
    toPage() {
      this.$store.commit("setIsSearch", false);
      this.$router.go(-1);
    },
    setInitValue() {
      if (this.$route.name === "Search" && this.$route.query.keywords) {
        this.search = this.$route.query.keywords;
        this.searchData();
      }
    },
    searchData() {
      this.$store.commit("setIsLoading", true);

      axios.defaults.headers = {
        id_brand: process.env.VUE_APP_BRAND,
        key: this.search,
        current_page: this.page,
        limit: this.limit,
      };
      axios
        .get("/api/v1/pages/search")
        .then((response) => {
          this.shop = response.data.data;
          this.totalPages = response.data.total_pages;
        })
        .catch((error) => {
          this.shop = [];
          this.totalPages = 0;
          this.message = error.response.data.api_msg;
        })
        .finally(() => {
          this.$store.commit("setIsLoading", false);
        });
    },
  },
  mounted() {
    this.setMetaInfo(null, this.$route.name);
    setTimeout(() => {
      this.setInitValue();
    });

    this.$store.commit("setIsSearch", true);
    this.$store.commit("setIsHome", false);
    this.$store.commit("setIsMemberPage", false);
    this.$store.commit("setActivePage", "SEARCH");
  },
};
</script>

<style scoped>
.margin-t {
  margin-top: 75px;
}

.search {
  color: #ffffff;
  background-color: white;
  position: relative;
  margin: auto;
}

.shop-item {
  position: relative;
  text-align: center;
}

.shop-img {
  max-width: 100%;
  height: auto;
  /* width: 250px;
    height: 250px;
    object-fit: cover; */
  cursor: pointer;
}
.shop-img:hover {
  opacity: 0.5;
}

.shop-img-m {
  /* width: 150px;
    height: 160px;
    object-fit: cover; */
  max-width: 100%;
  height: auto;
}

.text {
  font-size: 0.8rem;
  margin: 0;
}

.text-through {
  font-size: 0.8rem;
  color: #808080;
  text-decoration: line-through;
  margin: 0;
}

.empty-product {
  width: 100%;
  min-height: 100vh;
  position: relative;
}
.ep-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #c0c0c0;
  text-align: center;
  font-size: 24px;
}
</style>
